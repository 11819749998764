import { template as template_b2dcd96be4374a5c971543b2ab465c36 } from "@ember/template-compiler";
const SidebarSectionMessage = template_b2dcd96be4374a5c971543b2ab465c36(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
