import { template as template_8a90ef9108f349a7af14029c83e3290a } from "@ember/template-compiler";
const FKLabel = template_8a90ef9108f349a7af14029c83e3290a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
