import { template as template_351daa32034844c09690636d55c54d08 } from "@ember/template-compiler";
const FKControlMenuContainer = template_351daa32034844c09690636d55c54d08(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
